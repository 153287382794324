.btn-add-handle {
  padding: 0;
  margin: 0;
}

.node {
  .btn-add-handle {
    color: $gray-700;
    background-color: $white;
    position: absolute;
    cursor: pointer;
    top: 0;

    &.left {
      left: -5.3px;
    }

    &.right {
      right: -5.3px;
    }
  }
}

.react-flow__panel {
  box-shadow: 0px 0px 2px 0px $gray-400;

  .panel-item {
    background-color: #fff;
    padding: 6px;
    border: 1px solid $gray-300;
    border-bottom: 0px;

    &:last-child {
      border-bottom: 1px solid $gray-300;
    }

    &:hover {
      background-color: $gray-200;
      cursor: pointer;
    }
  }
}
